import { useMemo, useCallback, useReducer, ReactNode } from 'react';
import {
  setProcessingSuccess,
  setToggleLoginModal,
  setToggleProcessing,
  setToggleStakingDisabled,
  UIContext,
  UIInitialState,
  UIReducer,
} from 'src/reducers/uiReducer';

type PropsType = {
  children: ReactNode;
};

const UIProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(UIReducer, UIInitialState);

  const toggleLoginModal = useCallback(() => {
    dispatch(setToggleLoginModal(!state.isLogin));
  }, [state.isLogin]);

  const toggleProcessing = useCallback(() => {
    dispatch(setToggleProcessing(!state.isProcessing));
  }, [state.isProcessing]);

  const processingSuccess = useCallback(() => {
    dispatch(setProcessingSuccess(!state.isSuccess));
  }, [state.isSuccess]);

  const toggleStakingDisabled = useCallback(() => {
    dispatch(setToggleStakingDisabled(!state.stakingDisabled));
  }, [state.stakingDisabled]);

  const contextValue = useMemo(
    () => ({
      ...state,
      toggleLoginModal,
      toggleProcessing,
      processingSuccess,
      toggleStakingDisabled,
    }),
    [
      state,
      toggleLoginModal,
      toggleProcessing,
      processingSuccess,
      toggleStakingDisabled,
    ],
  );

  return (
    <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
  );
};

export default UIProvider;
