import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SuccessBlock from 'src/components/SuccessBlock/SuccessBlock';
import { PoolType } from 'src/reducers/poolReducer';
// import { useEthers, useNotifications } from '@usedapp/core';
// import {
//   FUND,
//   useBUSDAllowance,
//   useTokenPeggedValue,
//   useTokensOfOwnerByIndex,
//   useXUSDBalanceOf,
//   useXUSDBallanceInPool,
// } from 'src/hooks/contractHooks';
// import { formatEther } from 'ethers/lib/utils';
import InvestForm from '../InvestForm/InvestForm';
// import Processing from '../Processing/Processing';
import Warning from '../Warning/Warning';
import style from './InvestBlock.module.scss';
import useUI from '../../../hooks/use-ui';

type PropsType = {
  busd: number;
  poolId: number;
  // eslint-disable-next-line react/no-unused-prop-types
  pool: PoolType;
};

const InvestBlock: FC<PropsType> = ({ busd, poolId }) => {
  const { t } = useTranslation();
  const { isSuccess, processingSuccess } = useUI();

  const [amount, setAmount] = useState(0);

  const onInvestMoreClick = () => {
    processingSuccess();
  };

  return (
    <>
      <div className={style.description}>
        {t('poolPage.investments.description')}
      </div>
      {isSuccess && (
        <div className={style.successContainer}>
          <SuccessBlock>
            <div className={style.successContent}>
              <p>
                {t('poolPage.investments.success.sendSuccess')}{' '}
                <span>{amount} XUSD token</span>
              </p>
              <p>{t('poolPage.investments.success.processing')}</p>
            </div>
          </SuccessBlock>
          <div
            className={style.investMore}
            onClick={onInvestMoreClick}
            aria-hidden="true"
          >
            {t('poolPage.investments.success.moreInvest')}
          </div>
        </div>
      )}
      {!isSuccess && (
        <InvestForm busd={busd} poolId={poolId} setAmount={setAmount} />
      )}
      {/* {!isSuccess &&
        (!isProcessing ? (
          <InvestForm busd={busd} />
        ) : (
          <div className={style.progressBlock}>
            <Processing progress={100} />
          </div>
        ))} */}
      <div className={style.elucidation}>
        {t('poolPage.investments.explanation')}
      </div>
    </>
  );
};

export default InvestBlock;
