import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './StepsMainPage.module.scss';
import download from '../../assets/images/aboutUs/download.svg';
import externalLink from '../../assets/images/aboutUs/external-link.svg';
import buyToken from '../../assets/images/aboutUs/buyToken.svg';
import openPull from '../../assets/images/aboutUs/openPull.svg';
import flowDown from '../../assets/images/aboutUs/flow-down.svg';
import invest from '../../assets/images/aboutUs/invest.svg';
import arrow from '../../assets/images/aboutUs/arrow-on-table.svg';
import TableRate from '../TableRate/TableRate';

const StepsMainPage: FC = () => {
  const { t, i18n } = useTranslation();
  const walletLink = [
    { link: 'https://metamask.io/download/', title: 'Metamask' },
  ];
  const pancakeLink = [
    {
      link: 'https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x12446a71FCedB10AdfD275A1BC78D69Ac3450d2a',
      title: 'PancakeSwap',
    },
  ];
  return (
    <div className={style.container}>
      <div className={style.bgText}>steps</div>
      <div className={style.step}>
        <div className={style.step__icon}>
          <img src={download} alt="download" />
        </div>
        <div className={style.content}>
          <div className={style.content__header}>
            {t('mainPage.whoWe.steps.downloading.header')}
          </div>
          <div className={style.content__title}>
            {t('mainPage.whoWe.steps.downloading.content')}{' '}
            <span>Metamask</span>
          </div>
          <div className={style.walletLinks}>
            {walletLink.map(({ link, title }) => (
              <div className={style.link} key={title}>
                <a href={link} target="_blank" rel="noreferrer">
                  {title}
                </a>
                <img src={externalLink} alt="externalLink" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.step}>
        <div className={style.step__icon}>
          <img src={buyToken} alt="buytoken" />
        </div>
        <div className={style.content}>
          <div className={style.content__header}>
            {t('mainPage.whoWe.steps.buy.header')}
          </div>
          <div className={style.content__title}>
            {t('mainPage.whoWe.steps.buy.content')}
          </div>
          <div className={style.walletLinks}>
            {pancakeLink.map(({ link, title }) => (
              <div className={style.link} key={title}>
                <a href={link} target="_blank" rel="noreferrer">
                  {title}
                </a>
                <img src={externalLink} alt="externalLink" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.step}>
        <div className={style.step__icon}>
          <img src={openPull} alt="openPull" />
        </div>
        <div className={style.content}>
          <div className={style.content__header}>
            {' '}
            {t('mainPage.whoWe.steps.pool.header')}
          </div>
          <div className={style.content__title}>
            {t('mainPage.whoWe.steps.pool.content2')}
          </div>
          <div className={style.content__description}>
            {t('mainPage.whoWe.steps.pool.secondary')}
          </div>
        </div>
      </div>
      <div className={style.step}>
        <div className={style.step__icon}>
          <img src={invest} alt="invest" />
        </div>
        <div className={style.content}>
          <div className={style.content__header}>
            {t('mainPage.whoWe.steps.invest.header')}
          </div>
          <div className={style.content__title}>
            {t('mainPage.whoWe.steps.invest.content1')}
          </div>
          <div className={style.content__description}>
            {t('mainPage.whoWe.steps.invest.secondary1')} <br />
            {t('mainPage.whoWe.steps.invest.secondary2')}
          </div>
        </div>
      </div>
      <div className={style.step}>
        <div className={style.step__icon}>
          <img src={flowDown} alt="flowDown" />
        </div>
        <div className={style.content}>
          <div className={style.content__header}>
            {t('mainPage.whoWe.steps.staking.header')}
          </div>
          <div className={style.content__title}>
            {t('mainPage.whoWe.steps.staking.content')}
            <div className={style.arrowTable}>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </div>
        <div className={style.tableWrapper}>
          <div className={style.containerTable}>
            <TableRate />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsMainPage;
