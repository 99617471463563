import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import lock from 'src/assets/images/poll/lock.svg';
import style from './PollPermission.module.scss';

const PollPermission: FC = () => {
  const location = useLocation();
  const poolPage = location.pathname.includes('/pools');
  const { t } = useTranslation();
  const permissions = [
    { title: t('poolPage.permissions.plan') },
    { title: t('poolPage.permissions.documentation') },
    { title: t('poolPage.permissions.invest') },
  ];

  return (
    <>
      {permissions.map((el) => (
        <div
          key={el.title}
          className={poolPage ? style.item : `${style.item} ${style.itemMain}`}
        >
          <img src={lock} alt="lock" />
          <span>{el.title}</span>
        </div>
      ))}
    </>
  );
};

export default PollPermission;
