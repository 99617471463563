import { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-toastify';
import {
  changeInvestConfirmed,
  changeIsConfirmed,
  changeIsConfirmedStaking,
  changeIsStaking,
  changeStakingConfirmed,
  investMoney,
  PoolContext,
  PoolInitialState,
  poolReducer,
  PoolType,
  sendInvest,
  setCurrentPool,
} from 'src/reducers/poolReducer';

type PropsType = {
  children: ReactNode;
};

const PoolProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(poolReducer, PoolInitialState);

  const setPool = useCallback((pool: PoolType) => {
    dispatch(setCurrentPool(pool));
  }, []);

  const investPool = useCallback(async (stakingValue: number) => {
    try {
      await dispatch(investMoney(stakingValue));
      await dispatch(sendInvest(true));
    } catch (err) {
      toast.error('Send faild!');
    }
  }, []);

  const updateInvestConfirmed = useCallback((investConfirmed: number) => {
    dispatch(changeInvestConfirmed(investConfirmed));
  }, []);

  const updateConfirmed = useCallback((isConfirmed: boolean) => {
    dispatch(changeIsConfirmed(isConfirmed));
  }, []);

  const updateIsStaking = useCallback((isStaking: boolean) => {
    dispatch(changeIsStaking(isStaking));
  }, []);

  const updateStakingConfirmed = useCallback((stakingConfirmed: number) => {
    dispatch(changeStakingConfirmed(stakingConfirmed));
  }, []);

  const updateIsConfirmedStaking = useCallback(
    (isConfirmedStaking: boolean) => {
      dispatch(changeIsConfirmedStaking(isConfirmedStaking));
    },
    [],
  );

  const contextValue = useMemo(
    () => ({
      ...state,
      setPool,
      investPool,
      updateInvestConfirmed,
      updateConfirmed,
      updateIsStaking,
      updateStakingConfirmed,
      updateIsConfirmedStaking,
    }),
    [
      state,
      setPool,
      investPool,
      updateInvestConfirmed,
      updateConfirmed,
      updateIsStaking,
      updateStakingConfirmed,
      updateIsConfirmedStaking,
    ],
  );

  return (
    <PoolContext.Provider value={contextValue}>{children}</PoolContext.Provider>
  );
};

export default PoolProvider;
