import termsOfUse from 'src/assets/files/Terms_of_Use_X11_Fund.pdf';
import style from './TermsUse.module.scss';

const TermsUse = () => {
  return (
    <div>
      <iframe
        title="TermsUse"
        className={style.iframe}
        src={termsOfUse}
        frameBorder="0"
        allow="autoplay"
      />
    </div>
  );
};

export default TermsUse;
