import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import hourglass from 'src/assets/images/aboutUs/hourglass.svg';
import increasing from 'src/assets/images/aboutUs/increasing.svg';
import oilPump from 'src/assets/images/aboutUs/oil-pump.svg';
import money from 'src/assets/images/aboutUs/money.svg';
import BlockHeader from '../BlockHeader/BlockHeader';
import style from './AboutUs.module.scss';
import brush from '../../assets/images/aboutUs/brush.svg';
import StepsMainPage from '../StepsMainPage/StepsMainPage';
import SecondaryLine from '../SecondaryLine/SecondaryLine';

const AboutUs: FC = () => {
  const { t } = useTranslation();

  const analiticIcons = [
    { icon: increasing, title: t('mainPage.whoWe.analitic.stock') },
    { icon: money, title: t('mainPage.whoWe.analitic.currency') },
    { icon: oilPump, title: t('mainPage.whoWe.analitic.futures') },
    { icon: hourglass, title: t('mainPage.whoWe.analitic.horizon') },
  ];

  return (
    <div className={style.container}>
      <SecondaryLine />
      <BlockHeader header={t('mainPage.whoWe.header')} />
      <div className={style.aboutUs}>
        <div className={style.preview}>
          {t('mainPage.whoWe.description.item1')}
        </div>
        <br />
        <div className={style.preview}>
          {t('mainPage.whoWe.description.item2')}
        </div>
        <div className={`${style.preview} ${style.analitic}`}>
          {t('mainPage.whoWe.preview')}
        </div>
        <div className={style.analiticIcons}>
          {analiticIcons.map(({ icon, title }) => (
            <div className={style.itemIcon} key={title}>
              <div className={style.itemIcon__icon}>
                <img src={icon} alt={title} />
              </div>
              <div className={style.title}>{title}</div>
            </div>
          ))}
        </div>
        <div className={style.brush}>
          <img src={brush} alt="brush" />
        </div>
        <StepsMainPage />
      </div>
    </div>
  );
};

export default AboutUs;
