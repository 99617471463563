import { FC } from 'react';
import HeaderBlock from 'src/components/HeaderBlock/HeaderBlock';
import style from './InvestStaking.module.scss';

type PropsType = {
  children: React.ReactNode;
  header: string;
  point: string;
};

const InvestStaking: FC<PropsType> = ({ children, header, point }) => {
  return (
    <div className={style.container}>
      <div className={style.indicator}>
        <div className={style.background} />
        <div className={style.ellipse}>
          <span>{point}</span>
        </div>
      </div>
      <div className={style.mainBlock}>
        <div className={style.background} />
        <HeaderBlock title={header} />
        {children}
      </div>
    </div>
  );
};

export default InvestStaking;
