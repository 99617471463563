import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { convertData, toFinishDay } from 'src/helpers/convertDate';
import style from './PollTime.module.scss';

type PropsType = {
  startDate: string;
  endDate: string;
};

const PollTime: FC<PropsType> = ({ startDate, endDate }) => {
  const { t } = useTranslation();
  const toFinish = toFinishDay(startDate, endDate);
  return (
    <div className={style.container}>
      <div className={style.dateTitle}>
        <span>{t('poolPage.poolInfo.time.start')}</span>
        <span>{t('poolPage.poolInfo.time.end')}</span>
      </div>
      <div className={style.percent}>
        <div
          className={style.progress}
          style={{ width: `calc(${toFinish}% + 2px)` }}
        />
      </div>
      <div className={style.dateValue}>
        <span>{convertData(startDate)}</span>
        <span>{convertData(endDate)}</span>
      </div>
    </div>
  );
};
export default PollTime;
