import { FC, MouseEvent } from 'react';
import close from 'src/assets/images/poll/close.svg';
import metaMask from 'src/assets/images/modal/metaMask.svg';
import { BSC, useEthers } from '@usedapp/core';
import { toast } from 'react-toastify';
import { toastConfig } from 'src/helpers/static-data';

import useUI from '../../hooks/use-ui';
import styles from './LoginModal.module.scss';

const LoginModal: FC = () => {
  const { isLogin, toggleLoginModal } = useUI();
  const { activateBrowserWallet, switchNetwork, chainId } = useEthers();

  const closeModal = (e: MouseEvent<HTMLElement>) => {
    if (e.target !== e.currentTarget) return;
    toggleLoginModal();
  };

  const connectMetamask = async () => {
    try {
      await activateBrowserWallet();
      toast.success('Connected MetaMask success!', {
        position: 'top-center',
        ...toastConfig,
      });
      toggleLoginModal();
      // if (chainId !== BSC.chainId) {
      //  await switchNetwork(BSC.chainId);
      // }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error.message || 'Something went wrong!', {
        position: 'top-center',
        ...toastConfig,
      });
    }
  };

  return (
    isLogin && (
      <section className={styles.lightBox}>
        <div
          className={styles.container}
          onClick={closeModal}
          aria-hidden="true"
        >
          <div className={styles.modalCard}>
            <div
              className={styles.closeButton}
              onClick={toggleLoginModal}
              aria-hidden="true"
            >
              <img src={close} alt="close" />
            </div>
            <div className={styles.content}>
              <div className={styles.header}>LOGIN WITH WALLET</div>
              <div className={styles.providerWrapper}>
                <div
                  className={styles.providerContainer}
                  onClick={connectMetamask}
                  aria-hidden="true"
                >
                  <div className={styles.providerIcon}>
                    <img
                      className={styles.image}
                      src={metaMask}
                      alt="metaMask"
                    />
                  </div>
                  <div className={styles.provideName}>MetaMask</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default LoginModal;
