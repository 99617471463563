import { memo, useEffect, useReducer } from 'react';
import information from 'src/assets/images/poll/information.svg';
import {
  strategyReducer,
  setIsHidden,
  setState,
} from 'src/reducers/strategyReducer';
import { useTranslation } from 'react-i18next';
import style from './TableStrategy.module.scss';
import PopupBusiness from '../Poll/PopupBusiness/PopupBusiness';

type PropsType = {
  companies: Array<any>;
};

const TableStrategy = memo(({ companies }: PropsType) => {
  const [state, dispatch] = useReducer(strategyReducer, null);
  const [t, i18n] = useTranslation();

  console.log('companies1: ', companies);

  // companies.map((el) => console.log('company: ', el));

  useEffect(() => {
    if (companies) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const compan = companies.map((el: any, i: number) => ({
        ...el,
        hidden: true,
        symbol: el.companyid,
        name: el.companyname,
        id: el.companyid,
      }));
      console.log('companies3: ', compan);
      dispatch(setState(compan));
      // console.log('companies4: ', state);
    }
  }, [companies]);

  return (
    <div className={style.container}>
      {state?.map((company) => {
        const {
          symbol,
          description,
          hidden,
          name,
          id,
          companyid,
          companyname,
          companydescription_en: descriptionEn,
          companydescription_ru: descriptionRu,
        } = company;

        const handleClick = () => {
          dispatch(setIsHidden(companyid, !hidden));
        };

        const descriptionCompany =
          i18n.language === 'ru' ? descriptionRu : descriptionEn;

        return (
          <div className={style.business} key={id}>
            <div className={style.border} />
            <div className={style.business__name}>{symbol}</div>
            <img
              src={information}
              alt="information"
              onClick={handleClick}
              aria-hidden="true"
            />
            <p>{companyname}</p>
            {!hidden && (
              <PopupBusiness
                description={descriptionCompany}
                onClose={handleClick}
              />
            )}
          </div>
        );
      })}
    </div>
  );
});

export default TableStrategy;
