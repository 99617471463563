import { FC } from 'react';
import style from './BlockHeader.module.scss';

type PropsType = {
  header: string;
};

const BlockHeader: FC<PropsType> = ({ header }) => {
  return (
    <div className={style.container}>
      <h2 className={style.header}>{header}</h2>
    </div>
  );
};

export default BlockHeader;
