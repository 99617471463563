export const convertData = (dateConvert: string) => {
  const date = new Date(dateConvert);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  // console.log('START:', date);
  return `${day > 9 ? day : `0${day}`}.${
    month > 9 ? month : `0${month}`
  }.${date.getFullYear()}`;
};

export const convertData2 = (dateConvert: string) => {
  // console.log('DATE: ', dateConvert);
  const [d, m, y] = dateConvert.split('.');
  const dateStr = new Date(`${y}-${m}-${d}`);
  const date = new Date(dateStr);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day > 9 ? day : `0${day}`}.${
    month > 9 ? month : `0${month}`
  }.${date.getFullYear()}`;
};

export const toFinishDay = (startDate: string, endDate: string) => {
  const dateStart = Date.parse(startDate);
  const dateFinish = Date.parse(endDate);
  const today = new Date();
  const total = dateFinish - dateStart;
  const progress = +today - dateStart;
  const percent = Math.round((progress / total) * 100);
  if (percent > 100) {
    return 100;
  }
  if (percent < 0) {
    return 0;
  }
  return percent;
};
