import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAllPrismicDocumentsByType,
  PrismicRichText,
} from '@prismicio/react';
import {
  useTotalPools,
  useTotalStakedInPool,
  useTotalStakersInPool,
} from 'src/hooks/contractHooks';
import BlockHeader from '../BlockHeader/BlockHeader';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import style from './PollMainPage.module.scss';
// import heart from '../../assets/images/poll/heart-pulse.svg';
import bgLogo from '../../assets/images/mainPage/bg-pattern.svg';
import ItemPool from './ItemPool/ItemPool';

const PollMainPage: FC = () => {
  const { t, i18n } = useTranslation();
  // const poolId = 0;
  // const stakedInPool = useTotalStakedInPool(poolId);
  // const stakersInPool = useTotalStakersInPool(poolId);
  const totalPools = useTotalPools();
  // const pool = usePool(poolId);
  // const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
  // let stakedInPool = [];
  // let stakersInPool = [];
  /* for (let i = 0; i < totalPools; i++) {
    stakedInPool[i] = useTotalStakedInPool(i);
    stakersInPool[i] = useTotalStakersInPool(i);
  } */

  // console.log('Total Pools: ', totalPools.toString());
  const [posts, { state }] = useAllPrismicDocumentsByType('pool');
  const isLoaging = state === 'loading' || state === 'idle';

  return (
    <div className={style.wrapper}>
      <img className={style.bgLogo} src={bgLogo} alt="bg-logo" />
      <div className={style.container}>
        <SecondaryLine />
        <div className={style.poolHeader}>
          <BlockHeader header={t('mainPage.pool.header')} />
        </div>
        <div className={style.description}>
          {t('mainPage.pool.description')}
        </div>
        {isLoaging ? (
          <div className={style.spinner_wrap}>
            <span className={style.spinner} />
          </div>
        ) : (
          <div className={style.polls}>
            {posts
              ?.filter((el) => el.data.contractpoolid !== -1)
              ?.sort((a, b) => +a.uid - +b.uid)
              .map((el) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const {
                  isactive,
                  starts,
                  ends,
                  title_en: titleEn,
                  title_ru: titleRu,
                  cpid,
                  totalinvested,
                  totalinvestors,
                } = el.data;
                // const [slug] = el.slugs;
                const poolId = parseInt(cpid, 10);
                // const stakedInPool = useTotalStakedInPool(poolId);
                // const stakersInPool = useTotalStakersInPool(poolId);
                // let totalInvestors = el.uid === '1' ? 7 : 47;

                /* let amountInvested = el.uid === '0' ? 457530.0 : 10340.0;
                if (el.uid === '2') {
                  totalInvestors = 0;
                  amountInvested = 0;
                } */

                // console.log('el: ', el);
                return (
                  <ItemPool
                    key={el.id}
                    disabled={!isactive}
                    startDate={starts}
                    endDate={ends}
                    header={`${t('poolPage.poolInfo.header')}${+el.uid + 1}`}
                    uid={el.uid}
                    investors={totalinvestors}
                    busd={totalinvested}
                    title={
                      <PrismicRichText
                        field={i18n.language === 'en' ? titleEn : titleRu}
                      />
                    }
                  />
                );
              })}
          </div>
        )}
        <div className={style.condition} />
      </div>
    </div>
  );
};

export default PollMainPage;
