import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePool } from 'src/hooks/contractHooks';
import { PrismicText, usePrismicDocumentByUID } from '@prismicio/react';
import review from 'src/assets/images/poll/review.svg';
import waiting from 'src/assets/images/poll/waiting.svg';
import invest from 'src/assets/images/poll/invest.svg';
import style from './Strategy.module.scss';
import TableStrategy from '../TableStrategy/TableStrategy';
import HeaderBlock from '../HeaderBlock/HeaderBlock';
import Chart from '../../assets/images/poll/chart.jpg';

type PropsType = {
  isSubscribe: boolean;
  poolId: string;
};

const Strategy: FC<PropsType> = ({ isSubscribe, poolId }) => {
  const { t, i18n } = useTranslation();
  const currentPool = usePool(Number.parseInt(poolId, 10));

  const [post, { state }] = usePrismicDocumentByUID('pool', poolId.toString());
  // const poolId = parseInt(post?.data.cpid, 10);
  const isLoaging = state === 'loading' || state === 'idle';
  const isNotFound = state === 'failed';

  const [description, setDescription] = useState(null);
  const [companies, setCompanies] = useState(null);

  const points = [
    {
      icon: review,
      title: 'review',
      text: t('poolPage.strategy.points.first'),
    },
    {
      icon: waiting,
      title: 'waiting',
      text: t('poolPage.strategy.points.second'),
    },
    {
      icon: invest,
      title: 'invest',
      text: t('poolPage.strategy.points.third'),
    },
    {
      icon: review,
      title: 'review',
      text: t('poolPage.strategy.points.fourth'),
    },
    {
      icon: waiting,
      title: 'waiting',
      text: t('poolPage.strategy.points.fifth'),
    },
    {
      icon: invest,
      title: 'invest',
      text: t('poolPage.strategy.points.sixth'),
    },
  ];

  useEffect(() => {
    if (post) {
      // setCompanies(JSON.parse(currentPool?.companies));
      if (i18n.language === 'ru') {
        setDescription(post.data.description_ru);
      } else {
        setDescription(post.data.description_en);
      }
      setCompanies(post.data.companies);
    }
  }, [post, i18n.language]);

  return (
    <div className={style.container}>
      {!isSubscribe && (
        <div className={style.hiddenBlock}>
          <p>{t('poolPage.strategy.hiddenText')}</p>
        </div>
      )}
      <div className={isSubscribe ? style.content : style.contentHidden}>
        <HeaderBlock title={t('poolPage.strategy.header')} />
        {currentPool ? (
          <div className={style.description}>
            <PrismicText field={description} />
          </div>
        ) : (
          <div className={style.placeholder}>
            {t('poolPage.strategy.placeholder')}
          </div>
        )}
        {isSubscribe && currentPool && (
          <>
            {points.map(({ text, title }) => (
              <div className={style.point} key={title}>
                <span>{text}</span>
              </div>
            ))}
          </>
        )}
      </div>
      {isSubscribe && currentPool && (
        <TableStrategy companies={post?.data?.companies} />
      )}
      <img width={700} src={Chart} alt="Chart" style={{ marginLeft: 20 }} />
    </div>
  );
};
export default Strategy;
