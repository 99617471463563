import telegram from 'src/assets/images/footer/telegram.svg';
import twitter from '../assets/images/footer/twitter-fill.svg';
import facebook from '../assets/images/footer/facebook-box-fill.svg';
import instagram from '../assets/images/footer/instagram-fill.svg';
import medium from '../assets/images/footer/medium-fill.svg';
import group from '../assets/images/footer/x11_group.svg';
import finance from '../assets/images/footer/x11_finance.svg';
import bank from '../assets/images/footer/x11_bank.svg';

export const toastConfig = {
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const totalInvest = 4350;

export const tickerSymbols = [
  {
    proName: 'NASDAQ:AAPL',
    title: 'AAPL',
  },
  {
    proName: 'NASDAQ:TSLA',
    title: 'TSLA',
  },
  {
    proName: 'NASDAQ:GOOGL',
    title: 'GOOGL',
  },
  {
    proName: 'XETR:BMW',
    title: 'BMW',
  },
  {
    proName: 'COINBASE:BTCUSD',
    title: 'BTCUSD',
  },
  {
    proName: 'FOREXCOM:ETHUSD',
    title: 'ETHUSD',
  },
];

export const socialIcons: IconType[] = [
  {
    icon: facebook,
    title: 'facebook',
    linkEn: '',
    linkRu:
      'https://www.facebook.com/groups/875967167104465/?ref=share_group_link',
  },
  {
    icon: twitter,
    title: 'twitter',
    linkEn: 'https://twitter.com/x11finance',
    linkRu: '',
  },
  { icon: instagram, title: 'instagram', linkEn: '', linkRu: '' },
  {
    icon: medium,
    title: 'medium',
    linkEn: 'https://medium.com/@x_11',
    linkRu: '',
  },
  {
    icon: telegram,
    title: 'telegram',
    linkEn: 'https://t.me/x11finance2',
    linkRu: 'https://t.me/x11finance',
  },
];

export const financeIcons: IconType[] = [
  { icon: group, title: 'group' },
  { icon: finance, title: 'finance' },
  { icon: bank, title: 'bank' },
];

export const tableRate: RateType[] = [
  { min: 1, max: 5, rate: 5 },
  { min: 5, max: 15, rate: 8 },
  { min: 15, max: 30, rate: 10 },
  { min: 30, max: 50, rate: 15 },
  { min: 50, max: 70, rate: 20 },
  { min: 0, max: 70, rate: 30 },
];

export const risks: IRiskType[] = [
  {
    title: 'Название риска 1',
    description: `
      Adipiscing elit. Viverra ornare convallis nunc et, ultricies turpis.
      Platea ac et elit a. Tortor pretium nec egestas urna aenean ante `,
  },
  {
    title: 'Название риска 2',
    description: `
      Adipiscing elit. Viverra ornare convallis nunc et, ultricies turpis.
      Platea ac et elit a. Tortor pretium nec egestas urna aenean ante faucibus arcu massa.
      Ultricies nunc tellus vestibulum eu pretium integer quam integer nulla.
      Dui urna sed sit non, tristique arcu. Pretium pellentesque suspendisse odio eget.
      Tellus, dignissim euismod id in. `,
  },
  {
    title: 'Название риска 3',
    description: `
    Adipiscing elit. Viverra ornare convallis nunc et, ultricies turpis.
    Platea ac et elit a. Tortor pretium nec egestas urna aenean ante faucibus arcu massa.
    Ultricies nunc tellus vestibulum eu pretium`,
  },
];

export const strategyBusiness = [
  {
    title: 'AGRI',
    description: 'Agriforce Growing System',
    hidden: true,
    popup: `
    Rattler Midstream Padam - занимается предоставлением облачного
    решения для защиты конечных точек следующего поколения,
    которое предлагает облачные модули на своей
    платформе Falcon через модель на основе подписки SaaS.`,
  },
  {
    title: 'SAVE',
    description: 'Spirit Airls Inc',
    hidden: true,
    popup: 'Rattler Midstream Padam - занимается предоставлением облачного',
  },
  {
    title: 'DTST',
    description: 'Data Storage Corp',
    hidden: true,
    popup: 'Rattler Midstream Padam - занимается предоставлением облачного',
  },
  {
    title: 'PIXY',
    description: 'Shiftpixy Inc',
    hidden: true,
    popup: 'Rattler Midstream Padam - занимается предоставлением облачного',
  },
  {
    title: 'TVTX',
    description: 'Travere Therapeutics',
    hidden: true,
    popup: 'Rattler Midstream Padam - занимается предоставлением облачного',
  },
  {
    title: 'NNVC',
    description: 'Nanoviricides',
    hidden: true,
    popup: 'Rattler Midstream Padam - занимается предоставлением облачного',
  },
  {
    title: 'CASI',
    description: 'Casi Pharmaceuticals',
    hidden: true,
    popup: 'Rattler Midstream Padam - занимается предоставлением облачного',
  },
  {
    title: 'UXIN',
    description: 'Uxin Ltd.',
    hidden: true,
    popup: 'Rattler Midstream Padam - занимается предоставлением облачного',
  },
];

export const wallet = {
  status: 'success',
  balance: {
    busd: 4200,
    x11: 6500,
    xusd: 15000,
  },
};

export type ItemBlockType = {
  title?: string;
  description: string | string[];
};

export interface IRiskType {
  title: string;
  description: string;
}

export type IconType = {
  icon: string;
  title: string;
  linkRu?: string;
  linkEn?: string;
};

export type RateType = {
  min: number;
  max: number;
  rate: number;
};
