import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Status from '../Status/Status';
import style from './Balance.module.scss';

type PropsType = {
  icon: string;
  currency: string;
  type: string;
  balance: string;
};

const Balance: FC<PropsType> = ({ icon, balance, currency, type }) => {
  const { t } = useTranslation();

  const x11 = parseInt(balance, 10) > 6000;

  return (
    <div className={style.container}>
      <img src={icon} alt={currency} />
      <span className={style.balance}>{balance}</span>
      <span className={style.currency}>{currency}</span>
      {type === 'x11' && !x11 && (
        <div className={style.warning}>
          <Status
            title={`${t('poolPage.wallet.status.expected')} 6000 Х11`}
            type="error"
          />
        </div>
      )}
    </div>
  );
};

export default Balance;
