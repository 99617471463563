import { FC } from 'react';
import style from './PollCount.module.scss';

type PropsType = {
  count: string | number;
  title: string;
};

const PollCount: FC<PropsType> = ({ count, title }) => {
  return (
    <div className={style.pollCount}>
      <span className={style.count}>{count}</span>
      <span>{title}</span>
    </div>
  );
};
export default PollCount;
