import termsOfUse from 'src/assets/files/Privacy_Policy_X11_Fund.pdf';
import style from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => {
  return (
    <div>
      <iframe
        title="TermsUse"
        className={style.iframe}
        src={termsOfUse}
        frameBorder="0"
        allow="autoplay"
      />
    </div>
  );
};

export default PrivacyPolicy;
