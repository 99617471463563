import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './MainTitle.module.scss';
import logo from '../../assets/images/mainPage/x11-logo.svg';
import SecondaryLine from '../SecondaryLine/SecondaryLine';

const MainTitle: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.line}>
          <SecondaryLine
            left={t('mainPage.lineText.left')}
            center={t('mainPage.lineText.center')}
          />
        </div>
        <img className={style.mainLogo_first} src={logo} alt="x11-logo" />
        <div className={style.capsule}>
          <div className={style.center} />
          <div className={style.blick} />
          <div className={style.bottomShadow} />
        </div>
        <div className={style.title}>
          {t('mainPage.mainTitle')}
          <img className={style.mainLogo_second} src={logo} alt="x11-logo" />
        </div>
      </div>
    </div>
  );
};

export default MainTitle;
