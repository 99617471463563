export type QuestionType = {
  id: number;
  title: string;
  description: string | Array<string | { title: string; points: string[] }>;
  hidden: boolean;
};

export const faqReducer = (
  state: QuestionType[],
  action: ActionsType,
): QuestionType[] => {
  switch (action.type) {
    case 'faq/SET-IS-HIDDEN':
      return state.map((q) =>
        q.id === action.id ? { ...q, hidden: action.hidden } : q,
      );
    case 'faq/SET-NEW-STATE':
      return action.newState;
    default:
      return state;
  }
};

export const setIsHidden = (id: number, hidden: boolean) =>
  ({ type: 'faq/SET-IS-HIDDEN', id, hidden } as const);
export const setState = (newState: QuestionType[]) =>
  ({ type: 'faq/SET-NEW-STATE', newState } as const);

type ActionsType = ReturnType<typeof setIsHidden> | ReturnType<typeof setState>;
