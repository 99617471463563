import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsRu from './locales/ru/translation.json';
import translationsEn from './locales/en/translation.json';

// eslint-disable-next-line
// @ts-ignore
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ru: { translation: translationsRu },
      en: { translation: translationsEn },
    },
    fallbackLng: ['en'],
    debug: true,
    whitelist: ['en', 'ru'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: '.',
    },
  });

export default i18n;
