import { FC } from 'react';
import { IconType } from 'src/helpers/static-data';
import { useTranslation } from 'react-i18next';
import style from './SocialIcons.module.scss';

type PropsType = {
  icons: IconType[];
  width: string;
};

const SocialIcons: FC<PropsType> = ({ icons, width }) => {
  const { i18n } = useTranslation();

  return (
    <div className={style.container} style={{ width }}>
      {icons.map(({ icon, title, linkRu, linkEn }) => (
        <a
          key={title}
          href={i18n.language === 'ru' ? linkRu : linkEn}
          target="_blank"
          rel="noreferrer"
        >
          <img src={icon} alt={title} key={title} />
        </a>
      ))}
    </div>
  );
};

export default SocialIcons;
