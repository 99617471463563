import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer/Footer';
import cls from './NotFound.module.scss';

const NotFound = () => {
  return (
    <>
      <Header />
      <div className={cls.container}>
        <div className={cls.error_wrap}>
          <h1 className={cls.not_found_title}>404</h1>
          <p>Page not found</p>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default NotFound;
