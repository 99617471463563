import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import TimeRange from 'src/components/TimeRange/TimeRange';
import PollButton from 'src/components/Poll/PollButton/PollButton';
import PollPermission from 'src/components/Poll/PollPermission/PollPermission';
import PollCaount from 'src/components/Poll/PollCount/PollCount';
import { useNavigate } from 'react-router-dom';
import style from '../PollMainPage.module.scss';

type PropsType = {
  uid: string;
  disabled?: boolean;
  header?: string;
  investors?: number | string;
  busd?: number | string;
  startDate?: string;
  endDate?: string;
  title?: ReactNode;
};

const ItemPool: FC<PropsType> = ({
  uid,
  disabled,
  busd,
  investors,
  header,
  startDate,
  endDate,
  title,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const now = new Date();
  let headerValue = header;
  const openSoon = Date.parse(startDate) > +now;
  const closedPool = Date.parse(endDate) < +now;

  if (openSoon) {
    headerValue = t('poolPage.poolInfo.openSoon');
  }
  if (closedPool) {
    headerValue = t('poolPage.poolInfo.poolClosed');
  }

  const onPullOpenClick = () => {
    navigate(
      i18n.language === 'ru'
        ? `/${i18n.language}/pools/${uid}`
        : `/pools/${uid}`,
    );
  };

  return (
    <div className={style.openPoll}>
      {title && <div className={style.pollName}>{title}</div>}
      <h3 className={style.pollTitle}>{headerValue}</h3>
      <TimeRange
        startDate={startDate || '2022-01-01'}
        endDate={endDate || '2022-03-01'}
      />
      <div className={style.pollInfo}>
        <PollCaount count={busd || '0'} title={t('poolPage.poolInfo.busd')} />
        <PollCaount
          count={investors || '0'}
          title={t('poolPage.poolInfo.investors')}
        />
      </div>
      <div className={style.buttons}>
        <div className={style.buttonOpen}>
          <PollButton
            disabled={disabled}
            title={t('buttonOpenPool')}
            onButtonClick={onPullOpenClick}
          />
        </div>
        <div className={style.permissionBlock}>
          <PollPermission />
        </div>
      </div>
    </div>
  );
};

export default ItemPool;

ItemPool.defaultProps = {
  busd: '',
  investors: '',
  startDate: '',
  endDate: '',
  disabled: false,
  title: '',
  header: '',
};
