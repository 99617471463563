import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from 'src/assets/images/header/logo-x11.svg';
import home from 'src/assets/images/header/home.svg';
import { showItem } from 'src/helpers/showPageItem';
import style from './HeaderLogo.module.scss';

type PropsType = {
  language: string;
};

const HeaderLogo: FC<PropsType> = ({ language }) => {
  const location = useLocation();
  const showLogo = showItem(location.pathname);

  return (
    showLogo && (
      <div className={style.logoContainer}>
        <img src={logo} alt="logo" />
        <Link
          className={style.home}
          to={language === 'ru' ? `/${language}` : '/'}
        >
          <img src={home} alt="home" />
        </Link>
      </div>
    )
  );
};

export default HeaderLogo;
