import { createContext } from 'react';

export type PoolType = {
  title: string;
  status: string;
  rate: string;
  startDate: string;
  endData: string;
  busd: number;
  investots: number;
  x11: number;
  stakingValue: number;
  investSend: boolean;
  investConfirmed: number;
  isConfirmed: boolean;
  isStaking: boolean;
  stakingConfirmed: number;
  isConfirmedStaking: boolean;
};

export type PoolStateType = {
  pool: PoolType | null;
};

export const PoolInitialState: PoolStateType = {
  pool: null,
};

export const poolReducer = (
  state: PoolStateType,
  action: ActionsType,
): PoolStateType => {
  switch (action.type) {
    case 'x11-Foud/pool/SET-POOL':
      return {
        ...state,
        ...action.payload,
      };
    case 'x11-Foud/pool/INVEST-MONEY':
    case 'x11-Foud/pool/SEND-INVEST':
    case 'x11-Foud/pool/CHANGE-IS-CONFIRMED':
    case 'x11-Foud/pool/CHANGE-IS-STAKING':
    case 'x11-Foud/pool/CHANGE-IS-STAKING-CONFIRMED':
      return {
        ...state,
        pool: { ...state.pool, ...action.payload },
      };
    case 'x11-Foud/pool/CHANGE-INVEST-CONFIRMED':
      return {
        ...state,
        pool: {
          ...state.pool,
          investConfirmed: state.pool.investConfirmed + action.payload,
        },
      };
    case 'x11-Foud/pool/CHANGE-STAKING-CONFIRMED':
      return {
        ...state,
        pool: {
          ...state.pool,
          stakingConfirmed: state.pool.stakingConfirmed + action.payload,
        },
      };
    default:
      return state;
  }
};

export const setCurrentPool = (pool: PoolType) =>
  ({ type: 'x11-Foud/pool/SET-POOL', payload: { pool } } as const);

export const investMoney = (stakingValue: number) =>
  ({ type: 'x11-Foud/pool/INVEST-MONEY', payload: { stakingValue } } as const);

export const sendInvest = (investSend: boolean) =>
  ({ type: 'x11-Foud/pool/SEND-INVEST', payload: { investSend } } as const);

export const changeIsConfirmed = (isConfirmed: boolean) =>
  ({
    type: 'x11-Foud/pool/CHANGE-IS-CONFIRMED',
    payload: { isConfirmed },
  } as const);

export const changeInvestConfirmed = (investConfirmed: number) =>
  ({
    type: 'x11-Foud/pool/CHANGE-INVEST-CONFIRMED',
    payload: investConfirmed,
  } as const);

export const changeStakingConfirmed = (stakingConfirmed: number) =>
  ({
    type: 'x11-Foud/pool/CHANGE-STAKING-CONFIRMED',
    payload: stakingConfirmed,
  } as const);

export const changeIsStaking = (isStaking: boolean) =>
  ({
    type: 'x11-Foud/pool/CHANGE-IS-STAKING',
    payload: { isStaking },
  } as const);

export const changeIsConfirmedStaking = (isConfirmedStaking: boolean) =>
  ({
    type: 'x11-Foud/pool/CHANGE-IS-STAKING-CONFIRMED',
    payload: { isConfirmedStaking },
  } as const);

type ActionsType =
  | ReturnType<typeof setCurrentPool>
  | ReturnType<typeof investMoney>
  | ReturnType<typeof sendInvest>
  | ReturnType<typeof changeInvestConfirmed>
  | ReturnType<typeof changeIsConfirmed>
  | ReturnType<typeof changeIsStaking>
  | ReturnType<typeof changeStakingConfirmed>
  | ReturnType<typeof changeIsConfirmedStaking>;

export interface PoolContextValue extends PoolStateType {
  setPool: (pool: PoolType) => void;
  investPool: (stakingValue: number) => void;
  updateInvestConfirmed: (investConfirmed: number) => void;
  updateStakingConfirmed: (stakingConfirmed: number) => void;
  updateConfirmed: (isConfirmed: boolean) => void;
  updateIsStaking: (isStaking: boolean) => void;
  updateIsConfirmedStaking: (isConfirmedStaking: boolean) => void;
}

export const PoolContext = createContext<PoolContextValue>({
  ...PoolInitialState,
  setPool: () => {},
  investPool: () => {
    // console.log('Invest Pool!');
  },
  updateInvestConfirmed: () => {
    // console.log('Invest Pool!');
  },
  updateConfirmed: () => {},
  updateIsStaking: () => {},
  updateStakingConfirmed: () => {},
  updateIsConfirmedStaking: () => {},
});
