import { FC } from 'react';
import Spinner from 'src/components/Spinner/Spinner';
import style from './PollButton.module.scss';

type PropsType = {
  onButtonClick?: () => void;
  disabled?: boolean;
  title: string;
  loading?: boolean;
};

const PollButton: FC<PropsType> = ({
  onButtonClick,
  disabled,
  title,
  loading,
}) => {
  return (
    <button
      className={style.button}
      type="button"
      onClick={onButtonClick}
      disabled={disabled}
    >
      {loading ? <Spinner /> : title}
    </button>
  );
};

export default PollButton;

PollButton.defaultProps = {
  onButtonClick: () => {},
  loading: false,
  disabled: false,
};
