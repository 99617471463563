import { FC } from 'react';
import signout from 'src/assets/images/header/signout.svg';
import signin from 'src/assets/images/header/signin.svg';
import { useEthers } from '@usedapp/core';
import useUI from 'src/hooks/use-ui';
import { toast } from 'react-toastify';
import { toastConfig } from 'src/helpers/static-data';
import style from './LoginButton.module.scss';

const LoginButton: FC = () => {
  const { toggleLoginModal } = useUI();
  const { account, deactivate } = useEthers();

  const onLoginClick = async () => {
    if (account) {
      deactivate();
      toast.success('Disconnected Wallet!', {
        position: 'top-center',
        ...toastConfig,
      });
    } else {
      toggleLoginModal();
    }
  };

  return (
    <div className={style.item} onClick={onLoginClick} aria-hidden="true">
      {account ? (
        <img src={signout} alt="signout" />
      ) : (
        <img src={signin} alt="signin" />
      )}
    </div>
  );
};

export default LoginButton;
