import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Market from 'src/components/Market/Market';
import AboutUs from 'src/components/AboutUs/AboutUs';
import Advantages from 'src/components/Advantages/Advantages';
import ExampleBlock from 'src/components/ExampleBlock/ExampleBlock';
import Faq from 'src/components/FAQ/Faq';
import Footer from 'src/components/Footer/Footer';
import MainTitle from 'src/components/MainTitle/MainTitle';
import PollMainPage from 'src/components/PollMainPage/PollMainPage';
import Header from 'src/components/Header/Header';
import NewsBlock from 'src/components/NewsBlock/NewsBlock';
import style from './MainPage.module.scss';

const MainPage: FC = () => {
  const { t } = useTranslation();
  const commissions = [
    {
      title: t('mainPage.commissions.item1.title'),
      description: t('mainPage.commissions.item1.description'),
    },
    {
      title: t('mainPage.commissions.item2.title'),
      description: t('mainPage.commissions.item2.description'),
    },
    {
      title: t('mainPage.commissions.item3.title'),
      description: t('mainPage.commissions.item3.description'),
    },
  ];

  const advantages = [
    {
      description: [
        t('mainPage.advantages.points.point1'),
        t('mainPage.advantages.points.point2'),
        t('mainPage.advantages.points.point3'),
      ],
    },
    {
      description: [
        t('mainPage.advantages.points.point4'),
        t('mainPage.advantages.points.point5'),
      ],
    },
  ];

  return (
    <>
      <Header />
      <div className={style.container}>
        <MainTitle />
        <Market total={1502872} />
        <AboutUs />
        <ExampleBlock />
        <PollMainPage />
        <Advantages
          header={t('mainPage.commissions.header')}
          items={commissions}
        />
        <Advantages
          header={t('mainPage.advantages.header')}
          items={advantages}
          type="advantages"
        />
        <NewsBlock />
        {/* <RiskBlock /> */}
        <Faq />
        <Footer />
      </div>
    </>
  );
};

export default MainPage;
