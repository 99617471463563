import { createContext } from 'react';

export interface UIState {
  isLogin: boolean;
  isProcessing: boolean;
  isSuccess: boolean;
  stakingDisabled: boolean;
}

export const UIInitialState: UIState = {
  isLogin: false,
  isProcessing: false,
  isSuccess: false,
  stakingDisabled: true,
};

export const UIReducer = (state: UIState, action: ActionsType): UIState => {
  switch (action.type) {
    case 'ui/x11-found/TOGGLE-MAIN-MODAL':
    case 'ui/x11-found/TOGGLE-PROCESSING':
    case 'ui/x11-found/PROCESSING-SUCCESS':
    case 'ui/x11-found/TOGGLE-STAKING-DISABLED':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const setToggleLoginModal = (isLogin: boolean) =>
  ({
    type: 'ui/x11-found/TOGGLE-MAIN-MODAL',
    payload: { isLogin },
  } as const);

export const setToggleProcessing = (isProcessing: boolean) =>
  ({
    type: 'ui/x11-found/TOGGLE-PROCESSING',
    payload: { isProcessing },
  } as const);

export const setProcessingSuccess = (isSuccess: boolean) =>
  ({
    type: 'ui/x11-found/PROCESSING-SUCCESS',
    payload: { isSuccess },
  } as const);

export const setToggleStakingDisabled = (stakingDisabled: boolean) =>
  ({
    type: 'ui/x11-found/TOGGLE-STAKING-DISABLED',
    payload: { stakingDisabled },
  } as const);

type ActionsType =
  | ReturnType<typeof setToggleLoginModal>
  | ReturnType<typeof setToggleProcessing>
  | ReturnType<typeof setToggleStakingDisabled>
  | ReturnType<typeof setProcessingSuccess>;

interface UIContextValue extends UIState {
  toggleLoginModal: () => void;
  toggleProcessing: () => void;
  processingSuccess: () => void;
  toggleStakingDisabled: () => void;
}

export const UIContext = createContext<UIContextValue>({
  ...UIInitialState,
  toggleLoginModal: () => {},
  toggleProcessing: () => {},
  processingSuccess: () => {},
  toggleStakingDisabled: () => {},
});
