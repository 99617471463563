import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import { useTranslation } from 'react-i18next';
import BlockHeader from '../BlockHeader/BlockHeader';
import NewsPost from '../NewsPost/NewsPost';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import style from './NewsBlock.module.scss';

const NewsBlock = () => {
  const { t, i18n } = useTranslation();

  const [posts, { state }] = useAllPrismicDocumentsByType('news', {
    pageSize: 6,
  });
  const items = posts
    ?.filter((el) => el.data.language?.toLocaleLowerCase() === i18n.language)
    ?.sort(
      (a, b) => Date.parse(b?.data?.datetime) - Date.parse(a?.data?.datetime),
    )
    ?.filter((el, i) => i < 6);

  return (
    <div className={style.newsContainer}>
      <SecondaryLine />
      <BlockHeader header={t('mainPage.news.header')} />
      <div className={style.newsBlock}>
        {state === 'loading' ? (
          <div className={style.spinner_wrap}>
            <span className={style.spinner} />
          </div>
        ) : (
          items?.map((el: PrismicDocument) => {
            return <NewsPost key={el.id} post={el} />;
          })
        )}
      </div>
    </div>
  );
};

export default NewsBlock;
