import { FC } from 'react';
import bg_pattern from 'src/assets/images/poll/bg_pattern.png';
import { useTranslation } from 'react-i18next';
import { PoolType } from 'src/reducers/poolReducer';
import style from './ContainerInvest.module.scss';
import InvestStaking from '../Poll/InvestStaking/InvestStaking';
import InvestBlock from '../Poll/InvestBlock/InvestBlock';
import StakingBlock from '../Poll/StakingBlock/StakingBlock';

type PropsType = {
  balance: number;
  poolId: number;
  pool: PoolType;
};

const ContainerInvest: FC<PropsType> = ({ balance, poolId, pool }) => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <img className={style.bgPattern} src={bg_pattern} alt="bg_pattern" />
      <div className={style.bgText}>Yes! Let&apos;s go</div>
      <InvestStaking header={t('poolPage.investments.header')} point="1">
        <InvestBlock busd={balance} pool={pool} poolId={poolId} />
      </InvestStaking>
    </div>
  );
};

export default ContainerInvest;
