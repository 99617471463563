import { FC } from 'react';
import copy from 'src/assets/images/header/copy-wallet.svg';
import bank from 'src/assets/images/header/bank-line.svg';
import { toast } from 'react-toastify';
import { toastConfig } from 'src/helpers/static-data';
import { formatAddress } from 'src/helpers/showPageItem';
import { useLocation } from 'react-router-dom';
import style from '../Header/Header.module.scss';

type PropsType = {
  wallet: string;
};

const WalletAddress: FC<PropsType> = ({ wallet }) => {
  const location = useLocation();
  const isPools = `${location.pathname}`.includes('/pools');
  const styles = isPools
    ? style.address
    : `${style.address} ${style.addressAbs}`;
  const isMobile = navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
  );

  const copyWallet = async () => {
    await navigator.clipboard
      .writeText(wallet)
      .then(() => {
        toast.success('Copied success!', {
          position: 'top-center',
          ...toastConfig,
        });
      })
      .catch((error) => {
        toast.error(error || 'Something went wrong!', {
          position: 'top-center',
          ...toastConfig,
        });
      });
  };

  return (
    <div className={styles} onClick={copyWallet} aria-hidden="true">
      <img src={bank} alt="bank" />
      <p>{isMobile ? formatAddress(wallet) : wallet}</p>
      <img src={copy} alt="copy" />
    </div>
  );
};

export default WalletAddress;
