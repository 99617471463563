import { FC, useReducer } from 'react';
import { faqReducer, setIsHidden } from 'src/reducers/faqReducer';
import { useTranslation } from 'react-i18next';
import BlockHeader from '../BlockHeader/BlockHeader';
import style from './Faq.module.scss';
import down from '../../assets/images/faq/arrow-down.svg';
import up from '../../assets/images/faq/arrow-up.svg';
import SecondaryLine from '../SecondaryLine/SecondaryLine';

const Faq: FC = () => {
  const { t } = useTranslation();

  const questions = [
    {
      id: 1,
      title: t('mainPage.faq.questions.question1.title'),
      description: [
        t('mainPage.faq.questions.question1.description.item1'),
        t('mainPage.faq.questions.question1.description.item2'),
        t('mainPage.faq.questions.question1.description.item3'),
      ],
      hidden: true,
    },
    {
      id: 2,
      title: t('mainPage.faq.questions.question2.title'),
      description: t('mainPage.faq.questions.question2.description'),
      hidden: true,
    },
    {
      id: 3,
      title: t('mainPage.faq.questions.question3.title'),
      description: [
        t('mainPage.faq.questions.question3.description.item1'),
        t('mainPage.faq.questions.question3.description.item2'),
      ],
      hidden: true,
    },
    {
      id: 4,
      title: t('mainPage.faq.questions.question4.title'),
      description: t('mainPage.faq.questions.question4.description'),
      hidden: true,
    },
    {
      id: 5,
      title: t('mainPage.faq.questions.question5.title'),
      description: [
        {
          title: t('mainPage.faq.questions.question5.description.item1.title'),
          points: [
            t(
              'mainPage.faq.questions.question5.description.item1.points.point1',
            ),
            t(
              'mainPage.faq.questions.question5.description.item1.points.point2',
            ),
            t(
              'mainPage.faq.questions.question5.description.item1.points.point3',
            ),
          ],
        },
        t('mainPage.faq.questions.question6.description.item2'),
      ],
      hidden: true,
    },
    {
      id: 6,
      title: t('mainPage.faq.questions.question6.title'),
      description: [
        t('mainPage.faq.questions.question6.description.item1'),
        t('mainPage.faq.questions.question6.description.item2'),
        t('mainPage.faq.questions.question6.description.item3'),
      ],
      hidden: true,
    },
  ];
  const [state, dispatch] = useReducer(faqReducer, questions);

  function handleClick(id: number, hidden: boolean) {
    dispatch(setIsHidden(id, hidden));
  }

  return (
    <div className={style.container}>
      <SecondaryLine />
      <BlockHeader header={t('mainPage.faq.header')} />
      <div className={style.questionsWrapper}>
        <div className={style.questions}>
          {state.map(({ id, title, description, hidden }) => (
            <div
              className={hidden ? style.itemWrap : style.itemWrapShow}
              key={title}
              onClick={() => handleClick(id, !hidden)}
              aria-hidden="true"
            >
              <div className={style.number}>{id}</div>
              <div
                className={style.item}
                style={{ background: hidden ? '' : '#E9EBF2' }}
              >
                <img
                  className={style.arrow}
                  src={hidden ? down : up}
                  alt="arrow"
                />
                <div className={style.item__title}>{title}</div>
                {!hidden && (
                  <div className={style.item__description}>
                    {typeof description === 'string'
                      ? description
                      : description.map((el) =>
                          typeof el === 'string' ? (
                            <p key={el}>{el}</p>
                          ) : (
                            <div key={el.title}>
                              <p>{el.title}</p>
                              <ul>
                                {el.points.map((point) => (
                                  <li key={point}>{point}</li>
                                ))}
                              </ul>
                            </div>
                          ),
                        )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
