import { FC } from 'react';
import { financeIcons, socialIcons } from 'src/helpers/static-data';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showItem } from 'src/helpers/showPageItem';
import style from './Footer.module.scss';
import logo from '../../assets/images/footer/x11_fund.svg';
import chat from '../../assets/images/footer/wechat-line.svg';
import audit from '../../assets/images/footer/audit.png';
import SocialIcons from '../SocialIcons/SocialIcons';
import SecondaryLine from '../SecondaryLine/SecondaryLine';

const Footer: FC = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const isSecondaryLine = showItem(location.pathname);
  return (
    <footer className={style.container}>
      {!isSecondaryLine && <SecondaryLine />}
      <div className={style.logo}>
        <img src={logo} alt="logo" />
        <a
          className={style.audit}
          target="_blank"
          href="https://github.com/Quillhash/QuillAudit_Reports/blob/master/X11%20Fund%20Smart%20Contracts%20Audit%20Report%20-%20QuillAudits.pdf"
          rel="noreferrer"
        >
          <img src={audit} alt="audit" width={200} />
        </a>
      </div>
      <div className={style.linkInfo}>
        <Link
          to={`${
            i18n.language === 'ru'
              ? `/${i18n.language}/whitepaper`
              : `/whitepaper`
          }`}
          className={style.linkInfo__item}
        >
          <span>Whitepaper</span>
        </Link>
        <div className={`${style.linkInfo__item} ${style.item}`}>
          Smart contract
          <a href="https://bscscan.com/address/0x12446a71FCedB10AdfD275A1BC78D69Ac3450d2a">
            <span>0x12446a71FCedB10AdfD275A1BC78D69Ac3450d2a</span>
          </a>
        </div>
        <div className={style.linkInfo__item}>
          <a href="https://github.com/x11-finance/X11Fund-Smart-Contracts">
            GitHub
          </a>
        </div>
        <div className={style.linkInfo__item}>
          <a href="mailto:fund@x11.finance">fund@x11.finance</a>
        </div>
        <div className={style.linkInfo__item}>
          <a
            className={style.support}
            href="https://t.me/x11fund"
            target="_blank"
            rel="noreferrer"
          >
            <span>Support</span>
            <img src={chat} alt="chat" />
          </a>
        </div>
      </div>
      <div className={style.social}>
        <div className={style.socialLinkInfo}>
          <Link
            to={`${
              i18n.language === 'ru'
                ? `/${i18n.language}/terms-of-use`
                : `/terms-of-use`
            }`}
            className={style.linkInfo__item}
          >
            <span>Terms of Use</span>
          </Link>
          <Link
            to={`${
              i18n.language === 'ru'
                ? `/${i18n.language}/privacy-policy`
                : `/privacy-policy`
            }`}
            className={style.linkInfo__item}
          >
            <span>Privacy Policy</span>
          </Link>
        </div>
        <SocialIcons icons={socialIcons} width="144px" />
        <SocialIcons icons={financeIcons} width="219px" />
      </div>
    </footer>
  );
};

export default Footer;
