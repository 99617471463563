import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { tableRate } from 'src/helpers/static-data';
import style from './TableRate.module.scss';

const TableRate: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={style.header}>
        <div className={style.summary}>
          {t('mainPage.whoWe.steps.tableRate.amount')}
        </div>
        <div className={style.rate}>
          {t('mainPage.whoWe.steps.tableRate.bid')}
        </div>
      </div>
      {tableRate.map(({ min, max, rate }) => (
        <div className={style.item} key={`${max}_${rate}`}>
          <div className={style.summary}>
            {!!min && (
              <>
                {t('mainPage.whoWe.steps.tableRate.from')}{' '}
                <span>{min} 000</span> {t('mainPage.whoWe.steps.tableRate.to')}
              </>
            )}{' '}
            <span>
              {max}
              {!min && '+'} 000
            </span>{' '}
            BUSD
          </div>
          <div className={style.rate}>{rate}%</div>
        </div>
      ))}
    </>
  );
};
export default TableRate;
