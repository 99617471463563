import { PrismicText } from '@prismicio/react';
import { RTNode } from '@prismicio/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import close from 'src/assets/images/poll/close.svg';
import style from './PopupBusiness.module.scss';

type PropsType = {
  description: any;
  onClose: () => void;
};

const PopupBusiness: FC<PropsType> = ({ description, onClose }) => {
  const { t } = useTranslation();
  console.log('description1: ', description);

  return (
    <div className={style.container}>
      <h3 className={style.header}>{t('poolPage.strategy.business')}</h3>
      <img
        className={style.close}
        src={close}
        alt="close"
        onClick={onClose}
        aria-hidden="true"
      />
      <div className={style.description}>
        <PrismicText field={description} />
      </div>
    </div>
  );
};

export default PopupBusiness;
