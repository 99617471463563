import { FC } from 'react';
import success from 'src/assets/images/poll/success.svg';
import style from './SuccessBlock.module.scss';

type PropsType = {
  children: React.ReactNode;
};

const SuccessBlock: FC<PropsType> = ({ children }) => {
  return (
    <div className={style.container}>
      <div className={style.iconBlock}>
        <img src={success} alt="success" />
      </div>
      <div className={style.content}>{children}</div>
    </div>
  );
};

export default SuccessBlock;
