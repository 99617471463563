import { BSC, useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { toastConfig } from 'src/helpers/static-data';
import {
  FUND,
  useBUSDAllowance,
  useBUSDApprove,
  useBUSDBallance,
  useBUSDDeposit,
} from 'src/hooks/contractHooks';
// import usePool from 'src/hooks/use-pool';
import useUI from 'src/hooks/use-ui';
import PollButton from '../PollButton/PollButton';
import style from './InvestForm.module.scss';

type PropsType = {
  busd: number;
  poolId: number;
  setAmount: (am: number) => void;
};

const InvestForm: FC<PropsType> = ({ busd, poolId, setAmount }) => {
  const { t } = useTranslation();
  const { processingSuccess } = useUI();
  const [invest, setInvest] = useState('');
  const [sendLoading, setSendLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const { send: approve } = useBUSDApprove();
  const { send: busdDepositInPool } = useBUSDDeposit();
  // const poolId = 0;
  // const { notifications } = useNotifications();
  const { account, chainId, switchNetwork } = useEthers();
  const busdBalance = useBUSDBallance(account);
  const allowance = useBUSDAllowance(account, FUND);

  let disabled = true;
  if (!allowance) {
    disabled = true;
  } else if (allowance < parseInt(invest, 10)) {
    disabled = true;
  } else if (allowance >= parseInt(invest, 10)) {
    disabled = false;
  }

  console.log('poolId: ', poolId);

  const onInvestClick = async () => {
    // запустить ромашку вместо кнопки Send
    setSendLoading(true);
    setAmount(parseInt(invest, 10));
    // if (chainId !== BSC.chainId) {
    //  await switchNetwork(BSC.chainId);
    // }
    busdDepositInPool(poolId, utils.parseEther(invest))
      .then((res) => {
        if (!res) {
          toast.error('Error!', {
            position: 'top-center',
            ...toastConfig,
          });
        } else {
          toast.success('Deposited BUSD!', {
            position: 'top-center',
            ...toastConfig,
          });
          // показать SuccessBlock вместо кнопок
          processingSuccess();
        }
      })
      .catch(() => {
        // console.log(errorMsg);
      })
      .finally(() => {
        // убрать ромашку и вернуть кнопку
        setSendLoading(false);
      });
  };

  const onInvestChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (+value > busd) {
      setInvest(`${busd}`);
    } else {
      setInvest(e.target.value);
    }
  };

  const onApproveClick = async () => {
    // запустить ромашку вместо кнопки Approve
    setApproveLoading(true);
    toast.success('Approving BUSD!', {
      position: 'top-center',
      ...toastConfig,
    });
    // if (chainId !== BSC.chainId) {
    //  await switchNetwork(BSC.chainId);
    // }
    approve(FUND, utils.parseEther(invest))
      .then((res) => {
        if (!res) {
          toast.error('Error!', {
            position: 'top-center',
            ...toastConfig,
          });
          // убрать ромашку и вернуть кнопку
        } else {
          toast.success('Approved BUSD!', {
            position: 'top-center',
            ...toastConfig,
          });
          // убрать ромашку и вернуть кнопку
        }
      })
      .catch(() => {
        // console.log(errorMsg);
        // убрать ромашку и вернуть кнопку
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.inputBlock}>
        <input
          value={invest}
          onChange={onInvestChange}
          className={style.intupInvest}
          type="number"
          max={busd}
          placeholder={t('poolPage.investments.form.placeholder')}
        />
        <span>
          {t('poolPage.investments.form.available')}{' '}
          {busdBalance ? formatEther(busdBalance) : '0.0'} BUSD
        </span>
      </div>
      <div className={style.sendButton}>
        <PollButton
          title="Approve"
          onButtonClick={onApproveClick}
          loading={approveLoading}
          disabled={!disabled}
        />
      </div>
      <div className={style.sendButton}>
        <PollButton
          loading={sendLoading}
          title={t('poolPage.investments.form.send')}
          onButtonClick={onInvestClick}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default InvestForm;
