import ReactDOM from 'react-dom/client';
import './index.css';
import { DAppProvider, Config, BSC } from '@usedapp/core';

import { BrowserRouter } from 'react-router-dom';

import { PrismicProvider } from '@prismicio/react';
import { client } from './prismic';
import { BSCTestnetChain } from './helpers/bscTestnet';

import App from './App';
import reportWebVitals from './reportWebVitals';
import PoolProvider from './contexts/PollContext';
import UIProvider from './contexts/UIContext';

const config: Config = {
  readOnlyChainId: BSC.chainId, // BSCTestnetChain.chainId,
  readOnlyUrls: {
    [BSC.chainId]: BSC.rpcUrl,
    // [BSCTestnetChain.chainId]: BSCTestnetChain.rpcUrl,
  },
  networks: [BSC], // [BSCTestnetChain],
};

console.log('config: ', config);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <DAppProvider config={config}>
    <BrowserRouter>
      <PoolProvider>
        <UIProvider>
          <PrismicProvider client={client}>
            <App />
          </PrismicProvider>
        </UIProvider>
      </PoolProvider>
    </BrowserRouter>
  </DAppProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
