import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import walletIcon from 'src/assets/images/poll/wallet.svg';
import busd from 'src/assets/images/poll/busd.svg';
import x11_tocket from 'src/assets/images/poll/x11_tocket_coin.svg';
import bg_balance from 'src/assets/images/poll/bg_wallet.png';
import xusd from 'src/assets/images/poll/XUSD_coin.svg';
// import { wallet } from 'src/helpers/static-data';
import { useEthers } from '@usedapp/core';
import {
  useBUSDBallance,
  useTokenPeggedValue,
  useTokensOfOwnerByIndex,
  useX11Ballance,
  useXUSDBalanceOf,
} from 'src/hooks/contractHooks';
import { formatEther } from 'ethers/lib/utils';
import Balance from '../Balance/Balance';
import Status from '../Status/Status';
import style from './PollWallet.module.scss';

type PropsType = {
  x11: number;
};

const PollWallet: FC<PropsType> = () => {
  const { account } = useEthers();
  // const { balance } = wallet;
  const { t } = useTranslation();
  // const poolId = 0;
  const x11Balance = useX11Ballance(account);
  const busdBalance = useBUSDBallance(account);
  const xUSDBalance = useXUSDBalanceOf(account);
  const tokenId = useTokensOfOwnerByIndex(account, 0, xUSDBalance);
  const peggedValue = useTokenPeggedValue(tokenId, xUSDBalance);

  console.log('account: ', account);
  console.log('BUSD: ', busdBalance?.toString());
  // console.log('X11: ', x11Balance?.toString());

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.walletTitle}>
          <img src={walletIcon} alt="wallet" />
          <div className={style.title}>{t('poolPage.wallet.title')}</div>
        </div>
        <Status
          title={
            account
              ? t('poolPage.wallet.status.connected')
              : t('poolPage.wallet.status.disconnected')
          }
          type={account ? 'success' : 'disabled'}
        />
      </div>
      {account && (
        <div className={style.balanceBlock}>
          <img className={style.bgBalance} src={bg_balance} alt="bg_balance" />
          <Balance
            balance={busdBalance ? formatEther(busdBalance) : '0.0'}
            currency="BUSD"
            icon={busd}
            type="BUSD"
          />
          <Balance
            balance={x11Balance ? formatEther(x11Balance) : '0.0'}
            currency="X11"
            icon={x11_tocket}
            type="x11"
          />
          <Balance
            balance={peggedValue ? formatEther(peggedValue) : '0.0'}
            currency="XUSD"
            icon={xusd}
            type="XUSD"
          />
        </div>
      )}
    </div>
  );
};

export default PollWallet;
