import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import i18n from 'src/i18n';
import whitepaper from 'src/assets/images/header/file-list.svg';
import en from 'src/assets/images/header/en.svg';
import ru from 'src/assets/images/header/ru.svg';
import bgImage from 'src/assets/images/header/bg.svg';
import logo from 'src/assets/images/header/logo-x11.svg';
import { useEthers } from '@usedapp/core';
import { hiddenLang, showItem } from 'src/helpers/showPageItem';
import style from './Header.module.scss';
import WalletAddress from '../WalletAddress/WalletAddress';
// import LoginWallet from '../LoginWallet/LoginWallet';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import LoginButton from '../LoginButton/LoginButton';

type PropsType = {
  // changeLanguages: () => void;
  // language: string;
};

const Header: FC<PropsType> = () => {
  const { account } = useEthers();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState('en');
  const pathRu = `${location.pathname}`.includes('/ru');
  const isLang = hiddenLang(location.pathname);
  const showLogo = showItem(location.pathname);
  const changeLanguages = async () => {
    const lang = pathRu ? 'en' : 'ru';
    if (lang === 'ru') {
      navigate(`/ru${location.pathname}`);
    } else {
      const pathname = location.pathname.slice(3) || '/';
      navigate(pathname, { replace: true });
    }

    await i18n.changeLanguage(lang);
    await localStorage.setItem('x11-language', lang);
    setLanguage(i18n.language);
  };

  useEffect(() => {
    (async () => {
      const lang = await localStorage.getItem('x11-language');
      if (lang) {
        setLanguage(lang);
      } else {
        await localStorage.setItem('x11-language', i18n.language);
      }
    })();
  }, [language, location.pathname, navigate, pathRu]);

  const updateLanguage = () => {
    changeLanguages();
  };

  return (
    <div className={style.wrapper}>
      <img className={style.bgImage} src={bgImage} alt="bgImage" />
      <header className={style.header}>
        <HeaderLogo language={language} />
        <div
          className={style.headerBlock}
          style={{ width: showLogo ? '' : '100%' }}
        >
          <Link
            to={`${
              i18n.language === 'ru'
                ? `/${i18n.language}/whitepaper`
                : `/whitepaper`
            }`}
            className={style.item}
          >
            <img src={whitepaper} alt="whitepaper" />
            <p>whitepaper</p>
          </Link>
          {account && <WalletAddress wallet={account} />}
          <div className={style.logicBlock}>
            {!isLang && (
              <div
                className={style.language}
                onClick={updateLanguage}
                aria-hidden="true"
              >
                <img src={i18n.language === 'ru' ? ru : en} alt="flag" />
                <p>{t('header.lang')}</p>
              </div>
            )}
            {/* {account && <LoginWallet />} */}
            <LoginButton />
          </div>
        </div>
      </header>
      {showLogo && (
        <Link
          className={style.logoX11}
          to={language === 'ru' ? `/${language}` : '/'}
        >
          <img src={logo} alt="logo" />
        </Link>
      )}
    </div>
  );
};

export default Header;
