import { PoolType } from 'src/reducers/poolReducer';

const poolsList: PoolType[] = [
  {
    title: 'pool1',
    status: 'started',
    rate: '-18',
    startDate: '2022-05-15T10:00:00.000Z',
    endData: '2022-06-28T00:00:00.000Z',
    busd: 1395332,
    investots: 35,
    x11: 6200,
    stakingValue: 0,
    investSend: false,
    investConfirmed: 0,
    isConfirmed: false,
    isStaking: false,
    stakingConfirmed: 0,
    isConfirmedStaking: false,
  },
  // {
  //   title: 'pool3',
  //   status: 'started',
  //   rate: '-15',
  //   startDate: '2022-06-05T00:00:00.000Z',
  //   endData: '2022-08-01T00:00:00.000Z',
  //   busd: 395332,
  //   investots: 12,
  //   x11: 5200,
  //   stakingValue: 0,
  //   investSend: false,
  //   investConfirmed: 0,
  //   isConfirmed: false,
  //   isStaking: false,
  //   stakingConfirmed: 0,
  //   isConfirmedStaking: false,
  // },
  // {
  //   title: 'pool5',
  //   status: 'completed',
  //   rate: '+32',
  //   startDate: '2022-06-05T00:00:00.000Z',
  //   endData: '2022-06-21T00:00:00.000Z',
  //   busd: 395332,
  //   investots: 32,
  //   x11: 6200,
  //   stakingValue: 0,
  //   investSend: false,
  //   investConfirmed: 0,
  //   isConfirmed: false,
  //   isStaking: false,
  //   stakingConfirmed: 0,
  //   isConfirmedStaking: false,
  // },
  // {
  //   title: 'pool6',
  //   status: 'Стартовал 01.01.2022',
  //   rate: '-12',
  //   startDate: '2022-06-05T00:00:00.000Z',
  //   endData: '2022-08-01T00:00:00.000Z',
  //   busd: 395332,
  //   investots: 21,
  //   x11: 6200,
  //   investSend: true,
  //   isConfirmed: true,
  //   isStaking: false,
  //   isConfirmedStaking: false,
  // },
  // {
  //   title: 'pool7',
  //   status: 'Стартовал 01.01.2022',
  //   rate: '-11',
  //   startDate: '2022-06-05T00:00:00.000Z',
  //   endData: '2022-08-01T00:00:00.000Z',
  //   busd: 395332,
  //   investots: 22,
  //   x11: 6200,
  //   investSend: true,
  //   isConfirmed: true,
  //   isStaking: false,
  //   isConfirmedStaking: false,
  // },
  // {
  //   title: 'pool8',
  //   status: 'Стартовал 01.01.2022',
  //   rate: '+25',
  //   startDate: '2022-06-05T00:00:00.000Z',
  //   endData: '2022-08-01T00:00:00.000Z',
  //   busd: 395332,
  //   investots: 11,
  //   x11: 6200,
  //   investSend: true,
  //   isConfirmed: true,
  //   isStaking: true,
  //   isConfirmedStaking: false,
  // },
  // {
  //   title: 'pool9',
  //   status: 'Завершен',
  //   rate: '+18',
  //   startDate: '2022-06-05T00:00:00.000Z',
  //   endData: '2022-08-01T00:00:00.000Z',
  //   busd: 395332,
  //   investots: 23,
  //   x11: 6200,
  //   investSend: true,
  //   isConfirmed: true,
  //   isStaking: true,
  //   isConfirmedStaking: true,
  // },
];

export default poolsList;
