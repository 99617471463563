import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainPage from './content/pages/MainPage/MainPage';

import PollPage from './content/pages/PollPage/PollPage';
import Toast from './components/Toast/Toast';
import LoginModal from './components/LoginModal/LoginModal';
import Whitepaper from './content/pages/Whitepaper/Whitepaper';
import TermsUse from './content/pages/TermsUse/TermsUse';
import PrivacyPolicy from './content/pages/PrivacyPolicy/PrivacyPolicy';
// import ArticlePage from './content/pages/ArticlePage/ArticlePage';
import NotFound from './content/pages/NotFound/NotFound';

function App() {
  return (
    <div className="App">
      <Toast />
      <LoginModal />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/whitepaper" element={<Whitepaper />} />
        <Route path="/terms-of-use" element={<TermsUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pools/:id" element={<PollPage />} />
        {/* <Route path="/news/:slug" element={<ArticlePage />} /> */}
        <Route path="/ru">
          <Route index element={<MainPage />} />
          <Route path="/ru/whitepaper" element={<Whitepaper />} />
          <Route path="/ru/terms-of-use" element={<TermsUse />} />
          <Route path="/ru/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/ru/pools/:id" element={<PollPage />} />
        </Route>
        <Route path="*" element={<Navigate to="404" replace />} />
        <Route path="404" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
