import * as prismic from '@prismicio/client';

// Fill in your repository name
export const repositoryName = 'x11finance';

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6IngxMWZpbmFuY2UtNzMxMzRhYzAtZTc1NS00NmVmLThkMGUtYWZiYzFkZjc3OGQzXzQiLCJkYXRlIjoxNjY1NjY1NDUxLCJkb21haW4iOiJ4MTFmaW5hbmNlIiwiaWF0IjoxNjY1NjY1NDUxfQ.zBYgkjZVz-OjTn03mVGsa2V17577tJQGLPlr_5pnnv4',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
    {
      type: 'news',
      path: '/news',
    },
    {
      type: 'pool',
      path: '/pool',
    },
  ],
});
