import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContainerInvest from 'src/components/ContainerInvest/ContainerInvest';
import Footer from 'src/components/Footer/Footer';
import Header from 'src/components/Header/Header';
import ContinueBlock from 'src/components/Poll/ContinuesBlock/ContinuesBlock';
import PoolTabs from 'src/components/Poll/PoolTabs/PoolTabs';
import PollWallet from 'src/components/Poll/Wallet/PollWallet';
import Strategy from 'src/components/Strategy/Strategy';
import poolsList from 'src/helpers/pools';
import { wallet } from 'src/helpers/static-data';
import usePool from 'src/hooks/use-pool';
import { useEthers } from '@usedapp/core';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { formatEther } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';
import style from './PollPage.module.scss';
import {
  useBUSDBallance,
  useGetTokenIdFromStake,
  useHolderInPool,
  useTokenPeggedValue,
  useTokensOfOwnerByIndex,
  useXUSDBalanceOf,
  useXUSDBallanceInPool,
} from '../../../hooks/contractHooks';

const PollPage: FC = () => {
  const { id } = useParams();
  const idPool = parseInt(id, 10);
  const { pool, setPool } = usePool();
  const { account } = useEthers();
  const busdBalance = useBUSDBallance(account);
  const xUSDBalance = useXUSDBalanceOf(account);

  const tokenId = useTokensOfOwnerByIndex(account, 0, xUSDBalance);

  const peggedValue = tokenId >= 0 ? 1000.0 : 0.0;

  const busdBalanceN = busdBalance ? formatEther(busdBalance) : '0';
  const busdBalanceF = parseFloat(busdBalanceN);

  const xusdBalanceF = peggedValue;

  // check if the user has a stake in the pool
  const stakedTokenId = useGetTokenIdFromStake(idPool, account);
  console.log('stakedTokenId: ', stakedTokenId);

  const isHolderInPool =
    busdBalanceF >= 0 || xusdBalanceF >= 0 || stakedTokenId >= 0; // 0.2084554; // 1000
  const holderInPoolId = 1; // useHolderInPool(poolId, account);
  const holderInPool = holderInPoolId ? holderInPoolId >= 0 : false;
  const { balance } = wallet;
  const isSubscribe = true; // busdBalance >= BigInt('1000000000000000000000'); // BigInt('208455400000000000'); //

  const [post, { state }] = usePrismicDocumentByUID('pool', id);
  const poolId = parseInt(post?.data.cpid, 10);
  const isLoaging = state === 'loading' || state === 'idle';
  const isNotFound = state === 'failed';

  console.log('Pool: ', post);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    const item = poolsList.find((el) => el.title === id);
    setPool(item);
  }, [id, setPool]);

  if (!post) {
    return null;
  }

  return (
    <>
      <Header />
      <div className={style.wrapper}>
        <div className={style.container}>
          <div className={style.walletPoll}>
            <PollWallet x11={pool?.x11} />
            <ContinueBlock pool={post} />
          </div>
          {1 && isHolderInPool && (
            <>
              <Strategy isSubscribe poolId={id} />
              {isSubscribe && (
                <ContainerInvest
                  balance={balance.xusd}
                  poolId={poolId}
                  pool={pool}
                />
              )}
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PollPage;
