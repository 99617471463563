export const showItem = (path: string): boolean => {
  return (
    path.includes('/pools') || path.includes('/news') || path.includes('/404')
  );
};

export const hiddenLang = (path: string): boolean => {
  return path.includes('/news') || path.includes('/404');
};

export const formatAddress = (address: string) => {
  return `${address.slice(0, 5)}...${address.slice(-4)}`;
};
