import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import market from 'src/assets/images/mainPage/market.png';
import style from './Market.module.scss';

type PropsType = {
  total: number | string;
};

const Market: FC<PropsType> = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  // const totalInvestments = useTotalInvestments();

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.async = true;
    script.innerHTML = `
     {
      "symbols": [
        {
          "proName": "NASDAQ:AAPL",
          "title": "AAPL"
        },
        {
          "proName": "NASDAQ:TSLA",
          "title": "TSLA"
        },
        {
          "proName": "NASDAQ:GOOGL",
          "title": "GOOGL"
        },
        {
          "proName": "XETR:BMW",
          "title": "BMW"
        },
        {
          "proName": "COINBASE:BTCUSD",
          "title": "BTCUSD"
        },
        {
          "proName": "FOREXCOM:ETHUSD",
          "title": "ETHUSD"
        }
      ],
      "showSymbolLogo": true,
      "colorTheme": "light",
      "isTransparent": false,
      "displayMode": "regural",
      "locale": "en"
    }
      `;
    ref.current.appendChild(script);
  }, []);
  return (
    <div className={style.container}>
      <div className={style.market} aria-hidden="true">
        <div className="tradingview-widget-container">
          <div className="tradingview-widget-container__widget" ref={ref} />
          <div
            className="tradingview-widget-copyright"
            style={{ marginLeft: 'calc(100% - 205px)' }}
          >
            <a
              href="https://www.tradingview.com/markets/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="blue-text">Markets</span>
            </a>{' '}
            by TradingView Partners
          </div>
        </div>
        <div className={style.trading} />
      </div>
      <div className={style.imageBlock}>
        <div className={style.allInvest}>
          <p>{t('allInvest')}</p>
          <span>1 502 872 USD</span>
        </div>
        <img className={style.marketImage} src={market} alt="market" />
      </div>
    </div>
  );
};

export default Market;
