import { FC } from 'react';
import { ItemBlockType } from 'src/helpers/static-data';
import BlockHeader from '../BlockHeader/BlockHeader';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import style from './Advantages.module.scss';

type PropsType = {
  header: string;
  items: ItemBlockType[];
  type?: string;
};

const Advantages: FC<PropsType> = ({ header, items, type }) => {
  const styles =
    type === 'advantages' ? `${style.item} ${style.item_type}` : style.item;
  return (
    <div className={style.container}>
      <SecondaryLine />
      <BlockHeader header={header} />
      <div className={style.advantages}>
        {items.map(({ title, description }) => (
          <div className={styles} key={title || description[0]}>
            {title && <div className={style.item__title}>{title}</div>}
            <div className={style.item__description}>
              {typeof description === 'string'
                ? description
                : description.map((el) => <p key={el}>{el}</p>)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advantages;

Advantages.defaultProps = {
  type: '',
};
