import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import error from 'src/assets/images/poll/error-warning.svg';
// import { PoolType } from 'src/reducers/poolReducer';
import {
  useTotalStakedInPool,
  useTotalStakersInPool,
  FUND,
  // usePool,
  useHolderInPool,
  useX11Approve,
  useX11Deposit,
  useX11Ballance,
  useX11Allowance,
  useBUSDBallance,
} from 'src/hooks/contractHooks';
import { utils } from 'ethers';
import { toastConfig } from 'src/helpers/static-data';
import { toast } from 'react-toastify';
import { formatEther } from 'ethers/lib/utils';
import { BSC, useEthers } from '@usedapp/core';
import { PrismicDocument } from '@prismicio/types';
import style from './ContinuesBlock.module.scss';
import PollCaount from '../PollCount/PollCount';
import PollTime from '../PollTime/PollTime';
import PollOpenButton from '../PollOpenButton/PollOpenButton';
import PollPermission from '../PollPermission/PollPermission';

type PropsType = {
  pool: PrismicDocument;
};

const ContinueBlock: FC<PropsType> = ({ pool }) => {
  const { t } = useTranslation();
  const { account } = useEthers();
  const { send: approve } = useX11Approve();
  const { send: initDepositInPool } = useX11Deposit();

  console.log('account3: ', account);

  const poolId = pool.data.contractpoolid;
  const poolIsActive = pool.data.isactive;
  const totalInvested = pool.data.totalinvested;
  const totalInvestors = pool.data.totalinvestors;

  const totalInPool = useTotalStakedInPool(poolId);
  const stakersInPool = useTotalStakersInPool(poolId);
  // const currentPool = usePool(0);
  const isHolderInPool = useHolderInPool(poolId, account);
  let isOpenPool = true;
  const x11Ballance = useX11Ballance(account);
  const x11Allowance = useX11Allowance(account, FUND);
  const busdBalance = useBUSDBallance(account);
  let has1000BUSD = true; // false;
  let has6000X11 = false;
  let isAllowed = false;
  // const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
  // const { notifications } = useNotifications();

  const [openLoading, setOpenLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  // console.log('X11 Allowance: ', x11Allowance?.toString());

  if (x11Ballance >= utils.parseEther('6000')) {
    has6000X11 = true;
  }

  if (x11Allowance >= utils.parseEther('6000')) {
    isAllowed = true;
    // console.log('X11 is allowed');
  }

  if (busdBalance >= BigInt('0')) {
    has1000BUSD = true;
    // console.log('has 1000 BUSD: ', has1000BUSD);
    // console.log('BUSD Balance: ', busdBalance.toString()); // utils.formatEther(busdBalance));
    // console.log('ref: ', utils.parseEther('1000').toString());
  }
  // has1000BUSD = true;
  console.log('has1000BUSD: ', has1000BUSD);

  const onPullClick = async () => {
    // запустить ромашку вместо кнопки Open
    setOpenLoading(true);
    // if (chainId !== BSC.chainId) {
    // BSCTestnetChain.chainId) {
    //  await switchNetwork(BSC.chainId);
    // }
    initDepositInPool(poolId, utils.parseEther('6000'))
      .then((res2) => {
        if (!res2) {
          toast.error('Error!', {
            position: 'top-center',
            ...toastConfig,
          });
          // убрать ромашку и вернуть кнопку
        } else {
          isOpenPool = true;
          toast.success('The pool is open!', {
            position: 'top-center',
            ...toastConfig,
          });
          // убрать ромашку и вернуть кнопку
        }
      })
      .finally(() => {
        setOpenLoading(false);
      });
  };

  const onApproveClick = async () => {
    // запустить ромашку вместо кнопки Approve
    setApproveLoading(true);
    // if (chainId !== BSC.chainId) {
    //  await switchNetwork(BSC.chainId);
    // }
    approve(FUND, utils.parseEther('6000'))
      .then((res) => {
        if (!res) {
          toast.error('Error!', {
            position: 'top-center',
            ...toastConfig,
          });
          // убрать ромашку и вернуть кнопку
        } else {
          toast.success('Approved 6000 X11!', {
            position: 'top-center',
            ...toastConfig,
          });
          // убрать ромашку и вернуть кнопку
        }
      })
      .catch(() => {
        // console.log(errorMsg);
        // убрать ромашку и вернуть кнопку
      })
      .finally(() => {
        setApproveLoading(false);
      });
    toast.update('Approving 6000 X11!', {
      position: 'top-center',
      ...toastConfig,
    });
  };

  isOpenPool = true; // isHolderInPool && isHolderInPool >= 0;

  return (
    <div className={style.container}>
      <h3 className={style.pollTile}>{`${t(
        'poolPage.poolInfo.header',
      )}${1}`}</h3>
      <PollTime startDate={pool.data.starts} endDate={pool.data.ends} />
      <div className={style.pollInfo}>
        <PollCaount
          count={
            totalInvested /* totalInPool ? formatEther(totalInPool) : '0' */
          }
          title={t('poolPage.poolInfo.busd')}
        />
        <PollCaount
          count={
            totalInvestors /* stakersInPool ? stakersInPool.toString() : '0' */
          }
          title={t('poolPage.poolInfo.investors')}
        />
      </div>

      {!has1000BUSD && (
        <>
          <div className={style.warning}>
            <img src={error} alt="error" />
            <span>{t('poolPage.poolInfo.warning')}</span>
          </div>
          <div className={style.permissionsWrapper}>
            <PollPermission />
          </div>
        </>
      )}
    </div>
  );
};

export default ContinueBlock;
