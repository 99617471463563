import { Contract } from '@ethersproject/contracts';
import { useCall, useContractFunction } from '@usedapp/core';
import { Falsy } from '@usedapp/core/dist/esm/src/model/types';
import fundContractAbi from '../abi/Fund.json';
import x11ContractAbi from '../abi/X11.json';
import busdContractAbi from '../abi/BUSD.json';
import xusdContractAbi from '../abi/X721.json';
import stakingContractAbi from '../abi/ERC721Staking.json';

/* export const FUND = '0x6795B658A01c562B1636b45Dfa9308A8aE434F88';
export const X11 = '0x12446a71FCedB10AdfD275A1BC78D69Ac3450d2a';
export const BUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
export const XUSD = '0xA7d51dD76c2717edd8FCE92817961E2ecC083249';
export const STAKING = '0xBF23CfC8c970942282A94491C53b3a9803f80490'; */

export const FUND = '0x33f3c8e78C8bFE0D891199eB170C8F3D241f101f';
export const X11 = '0x12446a71FCedB10AdfD275A1BC78D69Ac3450d2a';
export const BUSD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
export const XUSD = '0x6102849e62cE7481Ce23064C975E62D6533878B8';
export const STAKING = '0xb294973D402202d33c4C032aD4b7b10e71406C1b';

const fundContract = new Contract(FUND, fundContractAbi);
const x11Contract = new Contract(X11, x11ContractAbi);
const busdContract = new Contract(BUSD, busdContractAbi);
const xusdContract = new Contract(XUSD, xusdContractAbi);
const stakingContract = new Contract(STAKING, stakingContractAbi);

export function useTotalInvestments() {
  const { value, error } =
    useCall({
      contract: fundContract, // instance of called contract
      method: 'getTotalInvestment', // Method to be called
      args: [], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useTotalStakedInPool(poolId: number | Falsy) {
  const { value, error } =
    useCall({
      contract: fundContract, // instance of called contract
      method: 'getTotalStakedInPool', // Method to be called
      args: [poolId], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useTotalStakersInPool(poolId: number | Falsy) {
  const { value, error } =
    useCall({
      contract: fundContract, // instance of called contract
      method: 'getStakersInThePool', // Method to be called
      args: [poolId], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useX11Ballance(address: string | Falsy) {
  const { value, error } =
    useCall({
      contract: x11Contract, // instance of called contract
      method: 'balanceOf', // Method to be called
      args: [address], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useBUSDBallance(address: string | Falsy) {
  const { value, error } =
    useCall({
      contract: busdContract, // instance of called contract
      method: 'balanceOf', // Method to be called
      args: [address], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    console.log('Error account: ', address, ' ', error.message);
    console.error('Error: ', error.message);
    return undefined;
  }
  return value?.[0];
}

export function useTotalPools() {
  const { value, error } =
    useCall({
      contract: fundContract, // instance of called contract
      method: 'getTotalPools', // Method to be called
      args: [], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  // console.log('Total: ', value ? value.toString() : 0);
  return value?.[0];
}

export function usePool(poolId: number | Falsy) {
  const { value, error } =
    useCall({
      contract: fundContract, // instance of called contract
      method: 'getPoolInfo', // Method to be called
      args: [poolId], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useHolderInPool(
  poolId: number | Falsy,
  address: string | Falsy,
) {
  const { value, error } =
    useCall({
      contract: fundContract, // instance of called contract
      method: 'isHolderInPool', // Method to be called
      args: [poolId, address], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useXUSDBallanceInPool(poolId: number | Falsy) {
  const { value, error } =
    useCall({
      contract: xusdContract, // instance of called contract
      method: 'getBalanceInPool', // Method to be called
      args: [poolId], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useGetTokenIdFromStake(
  poolId: number | Falsy,
  address: string | Falsy,
) {
  const { value, error } =
    useCall({
      contract: stakingContract, // instance of called contract
      method: 'getTokenId', // Method to be called
      args: [poolId, address], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useTokensOfOwnerByIndex(
  owner: string | Falsy,
  index: number | Falsy,
  xusdBalance: number | Falsy,
) {
  const { value, error } =
    useCall(
      xusdBalance > 0 && {
        contract: xusdContract, // instance of called contract
        method: 'tokenOfOwnerByIndex', // Method to be called
        args: [owner, index], // Method arguments - address to be checked for balance
      },
    ) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useTokenPeggedValue(
  id: number | Falsy,
  xusdBalance: number | Falsy,
) {
  const { value, error } =
    useCall(
      xusdBalance > 0 && {
        contract: xusdContract, // instance of called contract
        method: 'peggedAmount', // Method to be called
        args: [id], // Method arguments - address to be checked for balance
      },
    ) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useXUSDBalanceOf(address: string | Falsy) {
  const { value, error } =
    useCall({
      contract: xusdContract, // instance of called contract
      method: 'balanceOf', // Method to be called
      args: [address], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useBUSDAllowance(
  owner: string | Falsy,
  spender: string | Falsy,
) {
  const { value, error } =
    useCall({
      contract: busdContract, // instance of called contract
      method: 'allowance', // Method to be called
      args: [owner, spender], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useX11Allowance(
  owner: string | Falsy,
  spender: string | Falsy,
) {
  const { value, error } =
    useCall({
      contract: x11Contract, // instance of called contract
      method: 'allowance', // Method to be called
      args: [owner, spender], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useXUSDAllowance(id: number | Falsy) {
  const { value, error } =
    useCall({
      contract: xusdContract, // instance of called contract
      method: 'getApproved', // Method to be called
      args: [id], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    // console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useFundContractMethod(methodName: string) {
  const { state, send } = useContractFunction(fundContract, methodName, {});
  return { state, send };
}

export function useX11ContractMethod(methodName: string) {
  const { state, send } = useContractFunction(x11Contract, methodName, {});
  return { state, send };
}

export function useBUSDContractMethod(methodName: string) {
  const { state, send } = useContractFunction(busdContract, methodName, {});
  return { state, send };
}

export function useBUSDApprove() {
  const { state, send } = useContractFunction(busdContract, 'approve', {
    transactionName: 'Approve',
  });
  return { state, send };
}

export function useBUSDDeposit() {
  const { state, send } = useContractFunction(
    fundContract,
    'addBUSDStakeInPool',
    {
      transactionName: 'Deposit',
    },
  );
  return { state, send };
}

export function useX11Approve() {
  const { state, send } = useContractFunction(x11Contract, 'approve', {
    transactionName: 'Approve',
  });
  return { state, send };
}

export function useX11Deposit() {
  const { state, send } = useContractFunction(
    fundContract,
    'addStakeHolderInPool',
    {
      transactionName: 'Deposit',
    },
  );
  return { state, send };
}

export function useXUSDApprove() {
  const { state, send } = useContractFunction(xusdContract, 'approve', {
    transactionName: 'Approve',
  });
  return { state, send };
}

export function useXUSDDeposit() {
  const { state, send } = useContractFunction(stakingContract, 'stake', {
    transactionName: 'Deposit',
  });
  return { state, send };
}

export function useInitStaking() {
  const { state, send } = useContractFunction(stakingContract, 'initStaking', {
    transactionName: 'InitStaking',
  });
  return { state, send };
}
