import { FC, useEffect, useState } from 'react';
import style from './Status.module.scss';

type PropsType = {
  title: string;
  type: string;
};

const Status: FC<PropsType> = ({ title, type }) => {
  const [background, setBackground] = useState('#A1A6B3');

  useEffect(() => {
    if (type === 'success') {
      setBackground('#32D583');
    } else if (type === 'disabled') {
      setBackground('#A1A6B3');
    } else {
      setBackground('#F04438');
    }
  }, [type]);

  return (
    <div className={style.status}>
      <div className={style.led} style={{ background }} />
      <span>{title}</span>
    </div>
  );
};

export default Status;
