import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import diagram from 'src/assets/images/mainPage/diagram.png';
import diagram_eng from 'src/assets/images/mainPage/diagram_eng.png';
import style from './ExampleBlock.module.scss';

const ExampleBlock: FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className={style.wrapper}>
      <div className={style.bgText}>{t('mainPage.example.header')}</div>
      <div className={style.container}>
        <div className={style.description}>
          <h2>{t('mainPage.example.header')}</h2>
          <div className={style.content}>
            <div className={style.content__item}>
              {t('mainPage.example.paragraph1.first')}{' '}
              <div className={style.text}>(BEP-20)</div>.{' '}
              {t('mainPage.example.paragraph1.second')}
              <div className={style.tooltip}>
                {t('mainPage.example.paragraph1.tooltip')}
              </div>
            </div>
            <div className={style.content__item}>
              {t('mainPage.example.paragraph2.description')}
              <ul>
                <li>{t('mainPage.example.paragraph2.item1')}</li>
                <li>{t('mainPage.example.paragraph2.item2')}</li>
                <li>{t('mainPage.example.paragraph2.item3')}</li>
              </ul>
              <span>{t('mainPage.example.paragraph2.secondary')}</span>
            </div>
            <div className={style.content__item}>
              {t('mainPage.example.paragraph3.item')} <br />
              <br />
              1000-5%=50*0.40 = 125 {t(
                'mainPage.example.paragraph3.tokens',
              )}{' '}
              x11.
            </div>
            <div className={style.content__item}>
              {t('mainPage.example.paragraph4.item1')} <br />
              <br />
              {t('mainPage.example.paragraph4.item2')}
            </div>
          </div>
        </div>
        <div className={style.diagramBlock}>
          <img
            src={i18n.language === 'ru' ? diagram : diagram_eng}
            alt="diagram"
          />
        </div>
      </div>
    </div>
  );
};

export default ExampleBlock;
