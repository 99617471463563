import { FC } from 'react';
import style from './HeaderBlock.module.scss';

type PropsType = {
  title: string;
};

const HeaderBlock: FC<PropsType> = ({ title }) => {
  return (
    <div className={style.header}>
      <h2>{title}</h2>
      <div className={style.border} />
    </div>
  );
};

export default HeaderBlock;
