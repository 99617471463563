export type StrategyType = {
  symbol: string;
  name: string;
  hidden: boolean;
  description: string;
  companyname: string;
  companydescription_ru: string;
  companydescription_en: string;
  id: string;
  companyid: string;
};

export const strategyReducer = (
  state: StrategyType[] | null,
  action: ActionsType,
): StrategyType[] => {
  switch (action.type) {
    case 'strategyBusiness/SET-IS-HIDDEN':
      return state.map((q) =>
        q.id === action.id
          ? { ...q, hidden: action.hidden }
          : { ...q, hidden: true },
      );
    case 'strategyBusiness/SET-NEW-STATE':
      return action.newState;
    default:
      return state;
  }
};

export const setIsHidden = (id: string, hidden: boolean) =>
  ({ type: 'strategyBusiness/SET-IS-HIDDEN', id, hidden } as const);

export const setState = (newState: StrategyType[]) =>
  ({ type: 'strategyBusiness/SET-NEW-STATE', newState } as const);

type ActionsType = ReturnType<typeof setIsHidden> | ReturnType<typeof setState>;
