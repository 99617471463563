import style from './Spinner.module.scss';

const Spinner = () => {
  return (
    <div className={style.block}>
      <div className={style.spinner_wrap}>
        <span className={style.spinner} />
      </div>
    </div>
  );
};

export default Spinner;
